import * as React from 'react'
import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <div className="container">
        <div className="p-3 pb-md-4 mx-auto text-center">
          <h1 className="display-4 fw-normal">Hi Visitor,</h1>
          <p className="fs-5">Ini adalah website pribadi saya.<br/>Silakan lihat-lihat halaman lain untuk membaca postingan blog atau melihat project saya.</p>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
